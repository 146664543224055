import React from 'react'

const DemoAlert = () => {
    return (
        <div className="alert-demo">
            <div className="container p-3 text-center">
                Esta no es una tienda real.  Es una tienda de demostración del sistema Asistexa.  Más información en 
                { " " }<a href="http://www.asistexa.com"  rel="noopener noreferrer" target="_blank">http://www.asistexa.com</a>
            </div>
        </div>
    )
}

export default DemoAlert;
