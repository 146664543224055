
import * as firebase from "firebase/app";

import "firebase/auth";

firebase.initializeApp({
  apiKey: "AIzaSyApg7YHRl0aMqVfuT-M870ushv1kLhZnMs",
  authDomain: "asistexa-3ac3b.firebaseapp.com",
  databaseURL: "https://asistexa-3ac3b.firebaseio.com",
  projectId: "asistexa-3ac3b",
  storageBucket: "asistexa-3ac3b.appspot.com",
  messagingSenderId: "1093405897284",
  appId: "1:1093405897284:web:efc8ed0885767395024211",
  measurementId: "G-W9Y0NC0DC8"
});


export default firebase;