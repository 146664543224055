const Config = {
    apiURL: "https://soladv.artexacta.com/",
    pageSize: 12,
    asistexaURL: "https://soladv-sis.artexacta.com/",
    currentURL: "http://soladv.artexacta.com/",
    whatsappForOrder: "+59177775993",
    gpsDefaultPosition: {
        lat: -16.5422237,
        lng: -68.0826703
    },         
    GoogleMapApiKey: 'AIzaSyACQ-j10gQVtiERL-kXrT_G6DcJ07RCMSM',
    siteName: 'Sol Adventures',
    siteDescription: "Encuentra los mejores productos al mejor precio",
    slideshowBackgroundEnabled: false
}
export default Config

